var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-user",
      attrs: {
        right: "",
        "toggle-class": "d-flex align-items-center dropdown-user-link",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("div", { staticClass: "d-sm-flex d-none user-nav" }, [
                _c("p", { staticClass: "user-name font-weight-bolder mb-0" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.userData.fullName || _vm.userData.username) +
                      "\n      "
                  ),
                ]),
                _c("span", { staticClass: "user-status" }, [
                  _vm._v(_vm._s(_vm.userData.role)),
                ]),
              ]),
              _c(
                "b-avatar",
                {
                  staticClass: "badge-minimal",
                  attrs: {
                    size: "40",
                    src: _vm.userData.avatar,
                    variant: "light-primary",
                    badge: "",
                    "badge-variant": "success",
                  },
                },
                [
                  !_vm.userData.fullName
                    ? _c("feather-icon", {
                        attrs: { icon: "UserIcon", size: "22" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: { name: "pages-profile" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "UserIcon" },
          }),
          _c("span", [_vm._v("Profile")]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: { name: "apps-email" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "MailIcon" },
          }),
          _c("span", [_vm._v("Inbox")]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: { name: "apps-todo" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "CheckSquareIcon" },
          }),
          _c("span", [_vm._v("Task")]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: { name: "apps-chat" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "MessageSquareIcon" },
          }),
          _c("span", [_vm._v("Chat")]),
        ],
        1
      ),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: { name: "pages-account-setting" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "SettingsIcon" },
          }),
          _c("span", [_vm._v("Settings")]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: { name: "pages-pricing" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "CreditCardIcon" },
          }),
          _c("span", [_vm._v("Pricing")]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: { name: "pages-faq" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "HelpCircleIcon" },
          }),
          _c("span", [_vm._v("FAQ")]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: { "link-class": "d-flex align-items-center" },
          on: { click: _vm.logout },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "LogOutIcon" },
          }),
          _c("span", [_vm._v("Logout")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }