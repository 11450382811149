export function verticalRoute() {

    var routes = [
        {
            title: 'Scrubber',
            route: 'scrubber',
            icon: 'CpuIcon',
            hideOnEnvs: [],
            resource: 'Scrubber',
            action: 'read',
            children: [
                {
                    title: 'Scrub Claim',
                    route: 'scrub-claim',
                    icon: 'CircleIcon',
                    resource: 'Scrubber',
                    action: 'read',
                },
                {
                    title: 'Scrub Results',
                    route: 'scrub-results',
                    icon: 'CircleIcon',
                    resource: 'Scrubber',
                    action: 'read',
                }
            ],
        },
        {
            title: 'Practices',
            route: 'practices',
            icon: 'ClipboardIcon',
            resource: 'Practice',
            action: 'read',
        },
        {
            title: 'Insurance',
            route: 'insurance',
            icon: 'BriefcaseIcon',
            hideOnEnvs: [],
            children: [
                {
                    title: 'Ins. Companies',
                    route: 'ins-companies',
                    icon: 'CircleIcon',
                    resource: 'Insurance',
                    action: 'read',
                },
                {
                    title: 'Ins. Plans',
                    route: 'ins-plans',
                    icon: 'CircleIcon',
                    resource: 'Insurance',
                    action: 'read',
                },
            ],
        },
        {
            title: 'Procedures',
            route: 'procedures',
            icon: 'GridIcon',
            hideOnEnvs: [],
            children: [
                {
                    title: 'Proc. Types',
                    route: 'proc-types',
                    icon: 'CircleIcon',
                    resource: 'ProcedureType',
                    action: 'read',
                },
                {
                    title: 'Proc. Codes',
                    route: 'proc-codes',
                    icon: 'CircleIcon',
                    resource: 'ProcedureCode',
                    action: 'read',
                },
                {
                    title: 'Proc. Bundles',
                    route: 'proc-bundles',
                    icon: 'CircleIcon',
                    resource: 'ProcedureBundle',
                    action: 'read',
                },
            ],
        },
        {
            title: 'Fee Schedules',
            route: 'fee-schedules',
            icon: 'CalendarIcon',
            hideOnEnvs: [],
            resource: 'FeeSchedule',
            action: 'read',
        },
        {
            title: 'Settings',
            icon: 'SettingsIcon',
            children: [
                {
                    title: 'General Rules',
                    route: 'general-rules',
                    icon: 'CircleIcon',
                    resource: 'GeneralRule',
                    action: 'read',
                },
                {
                    title: 'Lists Options',
                    route: 'lists-options',
                    icon: 'CircleIcon',
                    resource: 'ListOptions',
                    action: 'read',
                },
                {
                    title: 'Users',
                    route: 'user-settings',
                    icon: 'CircleIcon',
                    resource: 'User',
                    action: 'read',
                },
            ],
        },
        // {
        //   title: 'Home',
        //   route: 'home',
        //   icon: 'HomeIcon',
        // },
        // {
        //   title: 'Second Page',
        //   route: 'second-page',
        //   icon: 'FileIcon',
        // },
    ]

    routes = routes.filter(x => !x.hideOnEnvs?.includes(process.env.VUE_APP_ENVIRONMENT))

    console.log(process.env.VUE_APP_ENVIRONMENT)

    return routes;
}


export default verticalRoute();