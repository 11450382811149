var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar-container d-flex content align-items-center" },
    [
      _c("ul", { staticClass: "nav navbar-nav d-xl-none" }, [
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "b-link",
              {
                staticClass: "nav-link",
                on: { click: _vm.toggleVerticalMenuActive },
              },
              [_c("feather-icon", { attrs: { icon: "MenuIcon", size: "21" } })],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-navbar-nav",
        { staticClass: "nav align-items-center ml-auto" },
        [
          _c(
            "b-nav-item-dropdown",
            {
              staticClass: "dropdown-user",
              attrs: {
                right: "",
                "toggle-class": "d-flex align-items-center dropdown-user-link",
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "d-sm-flex d-none user-nav" }, [
                        _c(
                          "p",
                          { staticClass: "user-name font-weight-bolder mb-0" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.user.name) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "user-status" }, [
                          _vm._v(_vm._s(_vm.user.role)),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-dropdown-item",
                { attrs: { "link-class": "d-flex align-items-center" } },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { size: "16", icon: "LogOutIcon" },
                  }),
                  _c("span", [_vm._v("Logout")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }